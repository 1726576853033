<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="searchParams.userName" size='small' style="width:150px;margin:5px;" placeholder='操作者'></a-input>
                <a-range-picker style="width:200px;margin:5px;"  :placeholder='rangeHolder' size='small' @change="changeTime" />
                <a-button  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
        </div>
        <div class="table-content" style="padding-top:20px;">
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
            </a-table>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            columns:[
                {title:'ID',dataIndex:'id'},
                {title:'操作者',dataIndex:'userName'},
                {title:'录入个数',dataIndex:'inputCount'},
                {title:'开始时间',dataIndex:'startTime'},
                {title:'结束时间',dataIndex:'endTime'},
            ],
            data:[],
            rangeHolder:['开始日期','结束日期'],
            searchParams:{
                limit:10,
                page:1,
                startTime:'',
                userName:'',
                endTime:'',
            },
            times:[],
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            
        }
    },
    computed:{

    },
    watch:{
    },
    created(){
        this.getData(this.searchParams)
    },
    methods:{
        async getData(params){
            this.compoilerTimes(this.times)
            this.loading = true
            const res = await this.axios('/platform/workLog/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total 
        },
        search(){
            this.searchParams.page = 1
            this.pagination.current = 1
            this.getData(this.searchParams)
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.searchParams.page = current
            this.searchParams.limit = pageSize
            this.getData(this.searchParams)
        },
        changeTime(result){
            this.times = result
        },
        compoilerTimes(array){
            if(array&&array.length>0){
                array.forEach((ele,index) => {
                    if(index == 0){
                        this.searchParams.startTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }else{
                        this.searchParams.endTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>